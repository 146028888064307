document.addEventListener('alpine:init', () => {
    Alpine.store('WepOverlayComponent', {
        history: [],

        trackHistory(id, type, name, parameters) {
            this.history.push({id: id, type: type, name: name, parameters: parameters});
        },

        clearHistory() {
            this.history = [];
        },

        forgetComponent(type, name, parameters) {
            this.history = this.history.filter(function (component) {

                if (component.type !== component.type) {
                    return true;
                }

                if (component.name !== name) {
                    return true;
                }

                if (parameters !== false && JSON.stringify(component.parameters) !== JSON.stringify(parameters)) {
                    return true;
                }

                return false;
            });
        }
    })
});

window.WepOverlayComponent = (config) => {
    return {
        type: config.type,
        open: false,
        onTop: true,
        activeComponent: false,
        showActiveComponent: false,
        activeComponentWidth: false,
        animationOverlapDuration: config.animationOverlapDuration,
        transitionFromDifferentType: false,
        store: Alpine.store('WepOverlayComponent'),
        init() {
            this.registerListeners();
        },
        getActiveComponentName() {
            if (this.$wire.get('components')[this.activeComponent] !== undefined) {
                return this.$wire.get('components')[this.activeComponent]['name'];
            }
        },
        getActiveComponentParameters() {
            if (this.$wire.get('components')[this.activeComponent] !== undefined) {
                return this.$wire.get('components')[this.activeComponent]['parameters'];
            }
        },
        getElementBehavior(key) {
            if (this.$wire.get('components')[this.activeComponent] !== undefined) {
                return this.$wire.get('components')[this.activeComponent]['element-behaviors'][key];
            }
        },
        getElementAttribute(key) {
            if (this.$wire.get('components')[this.activeComponent] !== undefined) {
                return this.$wire.get('components')[this.activeComponent]['element-attributes'][key];
            }
        },
        closeIf(closeBehavior) {
            if (this.getElementBehavior(closeBehavior) && this.onTop === true) {
                Livewire.emit(`${this.type}.close`);
            }
        },
        setActiveComponent(id) {
            if (this.activeComponent === id && this.transitionFromDifferentType === false) {
                this.open = true;
                return;
            }

            this.open = true;

            if (this.transitionFromDifferentType === false) {
                this.showActiveComponent = !this.activeComponent;
            }
            if (this.transitionFromDifferentType === true && this.activeComponent === id) {
                this.showActiveComponent = true;
            }
            if (this.transitionFromDifferentType === true && this.activeComponent !== id) {
                this.showActiveComponent = false;
            }

            this.transitionFromDifferentType = false;

            setTimeout(() => {
                this.activeComponent = id;
                this.showActiveComponent = true;
                this.activeComponentWidth = this.getElementAttribute('size');

                this.store.trackHistory(id, this.type, this.getActiveComponentName(), this.getActiveComponentParameters());
            }, (this.activeComponent !== false) ? this.animationOverlapDuration : 0);
        },
        closeActiveComponent() {
            Livewire.emit(`${this.type}.closing`, this.activeComponent);

            this.open = false;

            this.store.history.pop();
            let previousOverlayComponent = this.store.history[this.store.history.length - 1];

            if (previousOverlayComponent !== undefined && (previousOverlayComponent?.id !== this.activeComponent)) {
                this.store.history.pop();
                Livewire.emit(`${previousOverlayComponent.type}.componentActivated`, previousOverlayComponent.id);
                Livewire.emit(`${this.type}.closed`, {reset: false});
                return;
            }

            setTimeout(() => {
                this.activeComponent = false;
                this.showActiveComponent = false;
                this.activeComponentWidth = false;
            }, (this.activeComponent !== false) ? this.animationOverlapDuration : 0);

            Livewire.emit(`${this.type}.closed`);
        },
        registerListeners() {
            Livewire.on(`${this.type}.close`, (options) => {
                if ((options?.force ?? false) === true) {
                    this.store.clearHistory();

                    Livewire.emit('modal.close');
                    Livewire.emit('slide-over.close');
                }

                if ((options?.force ?? false) === false) {
                    this.closeActiveComponent();
                }
            });

            Livewire.on(`${this.type}.forget`, (component) => {
                this.store.forgetComponent(this.type, component.name, component.parameters);
            });

            Livewire.on(`${this.type}.closing`, (component) => {
                if (this.getElementBehavior('remove-state-on-close') === true) {
                    setTimeout(() => {
                        this.$wire.call('removeComponentFromState', component);
                    }, 500);
                }
            });

            Livewire.on(`${this.type}.closed`, (options) => {
                if ((options?.reset ?? true)) {
                    setTimeout(() => {
                        this.activeComponent = false;
                        this.$wire.resetState();
                    }, 300);
                }

                Livewire.emit('overlayComponentClosed', this.type);
            });

            Livewire.on(`${this.type}.componentActivated`, (id) => {
                Livewire.emit('overlayComponentActivated', this.type);
                this.setActiveComponent(id);
            });

            Livewire.on(`overlayComponentActivated`, (type) => {
                setTimeout(() => {
                    this.onTop = this.type === type;
                    this.transitionFromDifferentType = this.type !== type;
                }, (this.activeComponent !== false && this.type !== type) ? this.animationOverlapDuration : 0);
            });

            Livewire.on(`overlayComponentClosed`, (type) => {
                if (this.type !== type && this.open && this.transitionFromDifferentType === true && this.store.history.length === 0) {
                    Livewire.emit(`${this.type}.close`);
                }
                setTimeout(() => {
                    this.onTop = (this.transitionFromDifferentType === false);
                }, (this.activeComponent !== false) ? this.animationOverlapDuration : 0);
            });
        }
    };
};
